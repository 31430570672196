import * as XLSX from 'xlsx';

/**
 * Created by jiachenpan on 16/11/18.
 */

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  return format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  });
}

export function formatTime(time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) { // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  }
}

// 格式化时间
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 *get getByteLen
 * @param {Sting} val input value
 * @returns {number} output value
 */
export function getByteLen(val) {
  let len = 0
  for (let i = 0; i < val.length; i++) {
    if (val[i].match(/[^\x00-\xff]/ig) != null) {
      len += 1
    } else {
      len += 0.5
    }
  }
  return Math.floor(len)
}

export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

export function param(json) {
  if (!json) return ''
  return cleanArray(Object.keys(json).map(key => {
    if (json[key] === undefined) return ''
    return encodeURIComponent(key) + '=' +
      encodeURIComponent(json[key])
  })).join('&');
}

export function param2Obj(url) {
  const search = url.split('?')[1];
  if (!search) return {};
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
}

export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

export function objectMerge(target, source) {
  /* Merges two  objects,
     giving the last one precedence */

  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  for (const property in source) {
    if (source.hasOwnProperty(property)) {
      const sourceProperty = source[property]
      if (typeof sourceProperty === 'object') {
        target[property] = objectMerge(target[property], sourceProperty)
        continue
      }
      target[property] = sourceProperty
    }
  }
  return target
}

export function scrollTo(element, to, duration) {
  if (duration <= 0) return
  const difference = to - element.scrollTop
  const perTick = difference / duration * 10
  setTimeout(() => {
    console.log(new Date())
    element.scrollTop = element.scrollTop + perTick
    if (element.scrollTop === to) return
    scrollTo(element, to, duration - 10)
  }, 10)
}

export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

export const pickerOptions = [
  {
    text: '今天',
    onClick(picker) {
      const end = new Date()
      const start = new Date(new Date().toDateString())
      end.setTime(start.getTime())
      picker.$emit('pick', [start, end])
    }
  }, {
    text: '最近一周',
    onClick(picker) {
      const end = new Date(new Date().toDateString())
      const start = new Date()
      start.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: '最近一个月',
    onClick(picker) {
      const end = new Date(new Date().toDateString())
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: '最近三个月',
    onClick(picker) {
      const end = new Date(new Date().toDateString())
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      picker.$emit('pick', [start, end])
    }
  }]

export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/** 深拷贝*/
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'shallowClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  for (const keys in source) {
    if (source.hasOwnProperty(keys)) {
      if (source[keys] && typeof source[keys] === 'object') {
        targetObj[keys] = source[keys].constructor === Array ? [] : {}
        targetObj[keys] = deepClone(source[keys])
      } else {
        targetObj[keys] = source[keys]
      }
    }
  }
  return targetObj
}

/**
 * 处理unix时间戳，转换为可阅读时间格式
 * @param unix
 * @param format
 * @returns {*|string}
 */
export function unixToDate(unix, format) {
  let _format = format || 'yyyy-MM-dd hh:mm:ss'
  if (!unix) {
    return ''
  }
  const d = new Date(unix * 1000)
  const o = {
    'M+': d.getMonth() + 1,
    'd+': d.getDate(),
    'h+': d.getHours(),
    'm+': d.getMinutes(),
    's+': d.getSeconds(),
    'q+': Math.floor((d.getMonth() + 3) / 3),
    S: d.getMilliseconds()
  }
  if (/(y+)/.test(_format)) _format = _format.replace(RegExp.$1, (d.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (const k in o) if (new RegExp('(' + k + ')').test(_format)) _format = _format.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
  return _format
}

/**
 * 数组对象深拷贝
 * @param obj
 * @returns {*}
 */
export function cloneObj(obj) {
  let str = null
  let newobj = Array.isArray(obj) ? [] : {};
  if (typeof obj !== 'object') {
    return
  } else if (window && window.JSON) {
    str = JSON.stringify(obj) // 系列化对象
    newobj = JSON.parse(str) // 还原
  } else {
    for (var i in obj) {
      newobj[i] = typeof obj[i] === 'object' ? cloneObj(obj[i]) : obj[i]
    }
  }
  return newobj
}

function resetExcelData(keys, data) {
  return data.map(v => {
    return keys.map(j => {
      if (j === 'timestamp') {
        return parseTime(v[j])
      } else {
        return v[j]
      }
    })
  })
}

/**
 *
 * 导出模板
 * @param {*} data
 * @param {*} header
 * @param {*} keys
 * @param {*} name
 * @param {*} isRandomFileName
 */
export function handleDownload(data = [], header = [], keys = [], name = '数据表格', isRandomFileName = true) {
  let filename = `${name}_${isRandomFileName ? Date.now() : parseTime(new Date(), '{y}{m}{d}')}`
  import('./vendor/Export2Excel').then(excel => {
    data = resetExcelData(keys, data);
    excel.export_json_to_excel({
      header,
      data,
      filename,
      autoWidth: true,
      bookType: 'xlsx'
    });
  });
}
//导出多个sheets
export function handleDownload_sheets(data = [], header = [], keys = [],sheetNameList=[], name = '数据表格', isRandomFileName = true,) {
  let filename = `${isRandomFileName ? Date.now() : parseTime(new Date(), '{y}{m}{d}')}`
  import('./vendor/Export2Excel').then(excel => {
    let dataList=[]
    for (let i in data){
      let list=[]
      list = resetExcelData(keys[i], data[i]);
      dataList.push(list)
    }
    console.log(dataList)
    excel.export_json_to_excel_sheets({
      header,
      dataList,
      sheetNameList,
      filename,
      autoWidth: true,
      bookType: 'xlsx'
    });
  });
}
//导出图片表格
export function mergesImgDownload (data = [], header = [], keys = [], name = '数据表格', isRandomFileName = false, merges,mergeslength) {
  let filename = `${name}_${isRandomFileName ? Date.now() : parseTime(new Date(), '{y}{m}{d}')}`
  import('./vendor/Ezport2img').then(excel => {
    data = resetExcelData(keys, data);
    excel.export2Excel(
      header,
      data,
      filename,
      merges,
      mergeslength
    );
  });
}
/**导出失败原因文件 */
export function downloadErr (header = [], data = [], keys = [], name = '数据表格', isRandomFileName = false, merges,mergeslength) {
  let filename = `${name}_${isRandomFileName ? Date.now() : parseTime(new Date(), '{y}{m}{d}')}.xls`
  import('./vendor/Ezport2imgErr').then(excel => {
    excel.export2Excel(
      header,
      data,
      filename,
      merges,
      mergeslength
    );
  });
}
export function selfDefinedTemplate(data = [], header = [], keys = [], name = '数据表格', isRandomFileName = true) {
  let filename = `${name}_${isRandomFileName ? Date.now() : parseTime(new Date(), '{y}-{m}-{d}')}`
  import('./vendor/Export2Excel').then(excel => {
    data = resetExcelData(keys, data);
    excel.export_template_to_excel({
      header,
      data,
      filename,
      autoWidth: true,
      bookType: 'xlsx'
    });
  });
}
export function couponSelfDefinedTemplate(data = [], header = [], keys = [], name = '数据表格', isRandomFileName = true) {
  let filename = `${name}_${isRandomFileName ? Date.now() : parseTime(new Date(), '{y}-{m}-{d}')}`
  import('./vendor/Export2Excel').then(excel => {
    data = resetExcelData(keys, data);
    excel.couponExport_template_to_excel({
      header,
      data,
      filename,
      autoWidth: true,
      bookType: 'xlsx'
    });
  });
}
/** 下载模板 */
export function couponSelfDefinedDown(data = [], header = [], keys = [], name = '数据表格', isRandomFileName = true) {
  let filename = `${name}_${isRandomFileName ? Date.now() : parseTime(new Date(), '{y}-{m}-{d}')}`
  import('./vendor/Export2Excel').then(excel => {
    data = resetExcelData(keys, data);
    excel.couponExport_down_to_excel({
      header,
      data,
      filename,
      autoWidth: true,
      bookType: 'xlsx'
    });
  });
}

/**
 * 导出一个仅有表头的空模板
 * @param {*} filename
 * @param {*} header
 * @param {*} isRandomFileName
 */
export function downloadEmptyTemplate(filename, header = [], isRandomFileName = true) {
  handleDownload([], header, [], filename, isRandomFileName);
}

export function turnUrl(url) {
  let index = url.indexOf('?');
  url = url.substring(index + 1);
  let arr = url.split('&');
  let obj = {}
  for (let i = 0; i < arr.length; i++) {
    let arr1 = arr[i].split('=');
    obj[arr1[0]] = arr1[1];
  }
  return obj;
}
export function mergesHandleDownload(data = [], header = [], keys = [], name = '数据表格', isRandomFileName = true,merges) {
  let filename = name
  import('./vendor/Export2Excel').then(excel => {
    data = resetExcelData(keys, data);
    console.log(data);
    excel.export_json_to_excel({
      header,
      data,
      filename,
      autoWidth: true,
      bookType: 'xlsx',
      merges
    });
  });
}
export function mergeSkuList(data = [], type) {
  return data.reduce((results, item) => {
    let sku_list = item['order_items_dtos'] || item.sku_list;

    if (!sku_list) return;

    switch (type) {
      case 1:
        const {
          enterprise_goods_price,
          enterprise_freight_price,
          goods_price,
          freight_price
        } = item;

        item.price = 0;
        item.enterprise_total_price = 0;
        item.revise_price = 0;

        // 分销利润
        item.distribution_profit = (enterprise_goods_price + enterprise_freight_price) - (goods_price + freight_price);

        sku_list = sku_list.map((goods) => {
          if (!goods.state) goods.state = 0;

          item.price += goods.price * goods.num;
          item.enterprise_total_price += goods.enterprise_price * goods.num;
          item.revise_price += goods.revise_price * goods.num;


          goods.delivery_json = JSON.parse(goods.delivery_json);
          return goods;
        });
        break;
      case 2:
        item.order_total_price = 0;
        item.enterprise_purchase_price = 0;
        item.revise_price = 0;
        sku_list = sku_list.map(goods => {
          if (!goods.state) goods.state = 0;

          goods.shop_purchase_price = goods.shop_purchase_price || 0;

          item.order_total_price += goods.shop_purchase_price * goods.num;
          item.enterprise_purchase_price += goods.enterprise_purchase_price * goods.num;
          item.revise_price += goods.revise_price * goods.num;

          goods.delivery_json = JSON.parse(goods.delivery_json);

          return goods;
        })
        break;
      case 3:
        item.order_total_price = 0;
        sku_list = sku_list.map(goods => {
          goods.cost_price = goods.cost_price || 0;

          goods.delivery_json = JSON.parse(goods.delivery_json)
          goods.jiage = goods.cost_price;
          item.order_total_price += goods.cost_price * goods.num;

          return goods;
        })
        break;
    }

    results.push(
      ...sku_list.map((row, index) => {
        let span = {
          rowspan: 0,
          colspan: 0,
        };

        if (index === 0) {
          span = {
            rowspan: sku_list.length,
            colspan: 1,
          };
        }

        return {
          span,
          sn: item.sn || '',
          ship_button: item.ship_button,
          enterprise_name: item.enterprise_name || '',
          trade_sn: item.trade_sn || '',
          member_name: item.member_name,
          create_time: item.create_time,
          supplier_order_price: item.supplier_order_price,
          account_pay_money: item.account_pay_money,
          discount_card_voucher_price: item.discount_card_voucher_price,
          weixin_pay_money: item.weixin_pay_money,
          order_price: item.order_price,
          total_self_owned_orders_price: item.total_self_owned_orders_price,
          trade_status: item.trade_status,
          trade_status_text: item.trade_status_text,
          hangup_status: item.hangup_status,
          order_status_text: item.order_status_text,
          enterprise_total_price: item.enterprise_total_price,
          enterprise_purchase_price: row.enterprise_purchase_price,
          price: item.price,
          original_price: row.price,
          revise_price: item.revise_price,
          order_remark: item.order_remark,
          isCancelOrder: item.is_cancel_order,
          remark_update_date: item.remark_update_date,
          order_total_price: item.order_total_price,
          card_code: item.card_code,
          login_account: item.login_account,
          mobile:item.mobile,
          shop_combo_id: item.shop_combo_id,
          shop_combo_name: item.shop_combo_name,
          logistics_status: item.logistics_status,
          goods_image: row.goods_image || row.image,
          goods_name: row.name,
          goods_alias: row.goods_alias,
          goods_num: row.num,
          goods_type: row.goods_type,
          shop_price: row.shop_price,
          revise_exchange_money: row.revise_exchange_money,
          revise_exchange_point: row.revise_exchange_point,
          supplier_price: row.supplier_price,
          exchange_money: row.exchange_money,
          exchange_point: row.exchange_point,
          delivery: row.delivery_json && row.delivery_json[0],
          service_status: row.service_status,
          is_delivery: sku_list.map(item => item.service_status).filter(status => status !== 'COMPLETED').length > 0,
          state: row.state,
          logistics_status_text:row.logistics_status_text,
          seller_name: row.seller_name,
          order_source: row.order_source,
          jiage: row.jiage,
          shop_purchase_price: row.shop_purchase_price,
          shop_goods_price:item.shop_goods_price,
          shop_freight_price:item.shop_freight_price,
          order_status: item.order_status,
          shop_combo_order_price: item.shop_combo_order_price,
          distribution_profit: item.distribution_profit,
          consignee_name: item.consignee_name,
          consignee_mobile: item.consignee_mobile,
          consignee_province: item.consignee_province,
          consignee_province_id: item.consignee_province_id,
          consignee_city: item.consignee_city || '',
          consignee_city_id: item.consignee_city_id || '',
          consignee_county: item.consignee_county || '',
          consignee_county_id: item.consignee_county_id || '',
          consignee_town: item.consignee_town || '',
          consignee_town_id: item.consignee_town_id || '',
          consignee_address: item.consignee_address || '',
          ext_order_id: item.ext_order_id || '',
          remark: item.remark || '',

          card_name: item.card_name,
          trade_kind: item.trade_kind, // 是否补单
          source_trade_sn: item.source_trade_sn,
          platform_price: row.platform_price || '',
          enterprise_goods_price: row.enterprise_goods_price || '',
          enterprise_price: row.enterprise_price || '',
          repair_order_show: item.repair_order_show,
          showButton:!(sku_list.some(k => k.delivery_json && k.delivery_json[0]))
        };
      })
    );

    return results;
  }, []);
}
